const CONSTANTS = {
  EVENTS: {
    UPDATE_PUBLIC_API: 'updatePublicAPI',
    WIDGET_GFPP_CLICKED: 'widgetGfppClicked',
  },
  ACTIONS: {
    SWITCH_VARIATION: 'switchVariation',
  },
  APP_WIDGET_HELP_ID: 'b22b6385-70e3-45d8-81e0-3279360c256b',
} as const;

export default CONSTANTS;
