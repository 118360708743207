import constants from './constants';
import { removeHash } from './utils';
import _ from 'lodash';
import type { EditorSDK, ComponentRef } from '@wix/platform-editor-sdk';
import { Widget, Variation } from '../types/types';

const { ACTIONS } = constants;

const token = 'token';

const getVariationsOfWidget = (widgetRoot: any, widgetsData: Widget[]) =>
  _(widgetsData)
    .keyBy((widget) => removeHash(widget.rootCompId))
    .mapValues('variations')
    .get(widgetRoot);

const generatePresetsForAppWidget = async (
  editorSDK: EditorSDK,
  widgetsData: Widget[],
  componentRef: ComponentRef,
) => {
  const appWidgetData: Record<string, any> =
    await editorSDK.components.data.get(token, {
      componentRef,
    });

  return _(appWidgetData.controllerType)
    .thru((widgetRoot) => getVariationsOfWidget(widgetRoot, widgetsData))
    .map((variation: Variation) => ({
      variationId: removeHash(variation.rootCompId),
      displayName: variation.name,
    }))
    .value();
};

const openChangeVariationPanel = async (
  editorSDK: EditorSDK,
  widgetsData: Widget[],
  componentRef: ComponentRef,
) =>
  editorSDK.editor.openChangeVariationsPanel(token, {
    presets: await generatePresetsForAppWidget(
      editorSDK,
      widgetsData,
      componentRef,
    ),
    title: 'AppStudio_Design_Panel_Header',
    helpId: '11',
    keepOverrides: true,
  });

const handleGfppClicked = (
  editorSDK: EditorSDK,
  widgetsData: Widget[],
  { id: eventType, componentRef }: { id: string; componentRef: ComponentRef },
) => {
  switch (eventType) {
    case ACTIONS.SWITCH_VARIATION:
      openChangeVariationPanel(editorSDK, widgetsData, componentRef);
      break;
  }
};

export { handleGfppClicked };
