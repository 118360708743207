import _ from 'lodash';
import { removeHash } from './utils';
import constants from './constants';
import jsonSchemaDriver from '@wix/wix-json-schema-utils';
import type { PropertiesSchemas, Variation } from '../types/types';

const createWidgetManifest = (widget: any) => ({
  name: widget.name,
  propertiesSchema: widget.widgetApi.propertiesSchemas || [],
});

const createControllerStageData = ({
  name,
  variations,
}: {
  name: string;
  variations: Variation[];
}) => ({
  default: {
    gfpp: {
      desktop: {
        helpId: constants.APP_WIDGET_HELP_ID,
        iconButtons: {
          design: _.size(variations)
            ? {
                actionId: constants.ACTIONS.SWITCH_VARIATION,
              }
            : undefined,
        },
      },
      mobile: {
        helpId: constants.APP_WIDGET_HELP_ID,
      },
    },
    displayName: name,
    nickname: name,
  },
});

const pascalCase = (str: string) => _.upperFirst(_.camelCase(str));
const getEventName = (name: string) => `on${pascalCase(name)}`;

const generateWidgetEvents = (events: any) =>
  _(events)
    .keyBy((event) => getEventName(event.name))
    .mapValues((event) => ({
      type: event.name,
      description: event.description,
    }))
    .value();

const createPropertiesMembers = (
  propertiesSchemas: PropertiesSchemas,
  schemaDriver: any,
) =>
  _(propertiesSchemas)
    .mapKeys((prop) => _.head(_.keys(prop.structure)))
    .mapValues((prop) => {
      schemaDriver.set.schema(prop.structure);
      return { description: schemaDriver.get.description(), kind: 'member' };
    })
    .value();

const createFunctionMembers = (functions: any) =>
  _(functions)
    .mapKeys('name')
    .mapValues(({ description, params }) => ({
      description,
      params,
      kind: 'function',
    }))
    .value();

const createWidgetMembersData = (
  functions: any[],
  propertiesSchemas: PropertiesSchemas,
  schemaDriver: any,
) =>
  _.assign(
    createFunctionMembers(functions),
    createPropertiesMembers(propertiesSchemas, schemaDriver),
  );

const createWidgetExportsData = (
  {
    events,
    functions,
    propertiesSchemas,
  }: { events: any[]; functions: any[]; propertiesSchemas: PropertiesSchemas },
  schemaDriver: any,
) => ({
  eventHandlers: generateWidgetEvents(events),
  synthetic: false,
  inherits: {},
  members: createWidgetMembersData(functions, propertiesSchemas, schemaDriver),
});

const mergeCustomizer = (objValue: any, srcValue: string) => {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
};

export const generateAppManifest = (
  originalWidgets: any[] = [],
  definitions: any[],
  overrides: any = {},
) => {
  const appManifest: any = {
    exports: {},
    widgets: {},
    controllersStageData: {},
    customDefinitions: {},
  };

  appManifest.customDefinitions = _.reduce(definitions, _.assign, {});
  const schemaDriver = jsonSchemaDriver.createDriver(
    appManifest.customDefinitions,
  );

  // We don't want to pollute original widgets since generateAppManifest could be called on HMR of overrides bundle
  const widgets = _.cloneDeep(originalWidgets);

  _.forOwn(widgets, (widget) => {
    const overridesForWidget = overrides[widget.devCenterWidgetId];

    if (overridesForWidget) {
      _.mergeWith(widget.widgetApi, overridesForWidget, mergeCustomizer);
    }

    const controllerType = removeHash(widget.rootCompId);

    appManifest.widgets[controllerType] = createWidgetManifest(widget);
    appManifest.controllersStageData[controllerType] =
      createControllerStageData(widget);
    appManifest.exports[controllerType] = createWidgetExportsData(
      widget.widgetApi,
      schemaDriver,
    );
  });

  return appManifest;
};
