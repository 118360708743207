import { EditorSDK } from '@wix/platform-editor-sdk';

// Suffix -override is required because of internal implementation of editorSDK.info.getArtifactOverrides
// Check santa-core-utils/src/coreUtils/core/urlUtils.js#L459
const BLOCKS_API_OVERRIDE_QUERY = 'blocks-api-override';

// todo: use urlUtils instead
const LOCALHOST_REGEXP = /^https?:\/\/localhost:(\d*?)\//;
const PARASTORAGE_REGEXP = /^https?:\/\/static.parastorage.com\//;

const getExportsFromRawBundle = (rawBundle: any) => {
  const bundleModule: {
    exports?: any;
  } = {};

  try {
    // eslint-disable-next-line no-new-func
    new Function('module', 'exports', rawBundle)(bundleModule, {});
  } catch (error) {
    console.error('Failed to execute bundle with overrides.', error);
  }
  return bundleModule.exports || {};
};

const fetchBlocksApiOverrides = async (url: string, httpClient: any) =>
  httpClient.client
    .get(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/javascript',
      },
    })
    .then((response: any) =>
      response.data ? getExportsFromRawBundle(response.data) : {},
    )
    .catch((error: any) => {
      console.error(
        'Failed to load bundle for widgets API override.',
        error.message,
      );
      return {};
    });

const getOverridesUrl = async (editorSDK: EditorSDK) => {
  const artifactOverrides =
    (await editorSDK.info.getArtifactOverrides('token')) || {};
  return artifactOverrides[BLOCKS_API_OVERRIDE_QUERY];
};

const validateUrl = (url: string) =>
  PARASTORAGE_REGEXP.test(url) || LOCALHOST_REGEXP.test(url);

const getApiOverrides = async (editorSDK: EditorSDK, httpClient: any) => {
  const blocksApiOverridesUrl = await getOverridesUrl(editorSDK);

  return fetchBlocksApiOverrides(blocksApiOverridesUrl, httpClient);
};

const areOverridesAvailable = async (editorSDK: EditorSDK) => {
  const url = await getOverridesUrl(editorSDK);

  return Boolean(url && validateUrl(url));
};

export {
  BLOCKS_API_OVERRIDE_QUERY,
  areOverridesAvailable,
  validateUrl,
  getApiOverrides,
};
