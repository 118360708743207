import { generateAppManifest } from './manifestBuilder';
import constants from './constants';
import * as gfppClickHandler from './gfppClickHandler';
import { ExtendedPlatformEvent, Widget } from '../types/types';
import { getApiOverrides, areOverridesAvailable } from './widgetsApiOverrides';
import type { EditorPlatformApp, EditorSDK } from '@wix/platform-editor-sdk';

const { EVENTS } = constants;

function createEditorApp() {
  let _editorSDK: EditorSDK;
  let _essentials: any;
  let _shouldCheckOverrides = false;
  let _widgetsApiOverrides: any;

  const editorReady: EditorPlatformApp['editorReady'] = async (
    editorSDK: EditorSDK,
    _appDefId: string,
    options: { essentials: any },
  ) => {
    _editorSDK = editorSDK;
    _essentials = options.essentials;

    _shouldCheckOverrides = await areOverridesAvailable(editorSDK);
    _widgetsApiOverrides = _shouldCheckOverrides ? null : {};
  };

  const getAppManifest = async () => {
    const serializedWidgets =
      await _editorSDK.appBuilder?.widgets.getAllSerialized();
    const serializedDefinitions =
      (await _editorSDK.appBuilder?.definitions.getAllSerialized()) || [];

    return generateAppManifest(
      serializedWidgets,
      serializedDefinitions,
      _widgetsApiOverrides,
    );
  };

  const onEvent: EditorPlatformApp['onEvent'] = async ({
    eventType,
    eventPayload,
  }: ExtendedPlatformEvent) => {
    const serializedWidgets =
      (await _editorSDK.appBuilder?.widgets.getAllSerialized()) as Widget[];

    switch (eventType) {
      case EVENTS.UPDATE_PUBLIC_API:
        if (_shouldCheckOverrides) {
          // We use it for HMR so we can avoid refetching whole bundle and just apply changes
          self._overrideWixBlocksWidgetsApi = (overrides = {}) => {
            _widgetsApiOverrides = overrides;
            _editorSDK.application.reloadManifest();
          };

          if (!_widgetsApiOverrides) {
            _widgetsApiOverrides = await getApiOverrides(
              _editorSDK,
              _essentials.httpClient,
            );
          }
        }
        _editorSDK.application.reloadManifest();
        break;
      case EVENTS.WIDGET_GFPP_CLICKED:
        gfppClickHandler.handleGfppClicked(
          _editorSDK,
          serializedWidgets,
          eventPayload,
        );
        break;
    }
  };

  return {
    editorReady,
    getAppManifest,
    onEvent,
  };
}
export const { editorReady, getAppManifest, onEvent } = createEditorApp();
